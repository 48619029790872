import React from 'react'
import { Nav } from 'react-bootstrap'
import { Link } from 'gatsby'

import * as classes from './navbar.module.scss'

const links = [
  {
    name: 'Home',
    to: '/',
  },
  {
    name: 'News',
    to: '/news',
  },
  {
    name: 'Calendario',
    to: '/calendario',
  },
  {
    name: 'Classifica',
    to: '/classifica',
  },
  /*
  {
    name: 'Ticketing',
    to: '/ticketing',
  },
  */
  {
    name: 'Prima Squadra',
    to: '/prima-squadra',
  },
  {
    name: 'Giovanili',
    to: '/giovanili',
  },
  {
    name: 'Academy Cup',
    to: '/academycup',
  },
  {
    name: 'We Care',
    to: '/wecare',
  },
]

export const BottomLinks = () => (
  <>
    {links.map(({ name, to }) => (
      <Nav.Link
        as={Link}
        to={to}
        className={classes.navLink}
        activeClassName={classes.active}
        partiallyActive={to !== '/'}
        key={name}
      >
        {name}
      </Nav.Link>
    ))}

    <Nav.Link
      href={'https://store.pallacanestroreggiana.it/'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={classes.navLink}
    >
      Store
    </Nav.Link>
  </>
)
